/* App.css */
body, html {
    
    background-color: #338550;
  }
  
  .app {
    min-height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    padding: 0;
    margin: 0;
  }
  body {
    touch-action: pan-x pan-y;
  }
  
  .app-content {
    width: 100%;
  }
  
  .overlay-text {
    position: absolute;

  }
  
  /* Additional styling for your content */
  .app-content img {
    width: 100%;
    height: auto;
  }
  
  h1{
    color: black;
  }